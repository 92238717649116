@use 'abstracts/shared';

// Checkboxes
$form-check-input-width: 1.5em;
$form-check-min-height: 1rem;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0.125rem;

// Colors
$form-check-input-bg: shared.color('bg100');
$form-check-input-bg-disabled: shared.color('text200');
$form-check-input-border-color: shared.color('primary300');
$form-check-input-border: 2px solid $form-check-input-border-color;
$form-check-border-color-hover: shared.color('primary500');
$form-check-border-color-active: shared.color('primary700');
$form-check-border-color-focus: shared.color('primary700');
$form-check-border-color-error: shared.color('error600');
$form-check-input-border-radius: 6px;
$form-check-input-border-radius-sm: 4px;
$form-check-input-checked-color: shared.color('bg100');
$form-check-radio-checked-color: shared.color('primary500');
$form-check-input-checked-bg-color: shared.color('primary500');
$form-check-input-checked-bg-color-hover: shared.color('primary600');
$form-check-input-checked-bg-color-active: shared.color('primary700');
$form-check-input-checked-bg-color-focus: shared.color('primary500');
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-label-color-disabled: shared.color('text300');
$form-check-input-color-disabled: $form-check-label-color-disabled;

// Checked SVG icons.
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m3.75 9.75 2.5 2.5 6-8.5' stroke='#{$form-check-input-checked-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
$form-check-input-checked-bg-image-disabled: url("data:image/svg+xml,<svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m3.75 9.75 2.5 2.5 6-8.5' stroke='#{$form-check-input-color-disabled}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");

// Radio SVG icons.
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-radio-checked-color}'/></svg>");
$form-check-radio-checked-bg-image-disabled: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-input-color-disabled}'/></svg>");

// Indeterminate SVG icons.
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.75 7.25a.75.75 0 0 0 0 1.5v-1.5Zm8.5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-8.5 0h8.5v-1.5h-8.5v1.5Z' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-indeterminate-bg-image-disabled: url("data:image/svg+xml,<svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.75 7.25a.75.75 0 0 0 0 1.5v-1.5Zm8.5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-8.5 0h8.5v-1.5h-8.5v1.5Z' fill='#{$form-check-input-color-disabled}'/></svg>");

.form-check {
  display: block;
  min-height: $form-check-min-height;
  line-height: 1.5em;
  padding-left: $form-check-padding-start;

  .form-hint {
    font-size: 0.875em;
    line-height: 1.25em;
    padding: 0;
  }

  &.form-check-normal {
    font-size: 1rem;
  }

  &.form-check-small {
    font-size: 0.75rem;
  }
}

.form-check-label {
  cursor: pointer;
}

.form-check-input {
  float: left;
  width: $form-check-input-width;
  height: $form-check-input-width;
  vertical-align: top;
  background-position: center;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  print-color-adjust: exact;
  outline: 0;
  cursor: pointer;
  margin-left: $form-check-padding-start * -1;
  background-size: 1em;

  // Border radius.
  &[type='checkbox'] {
    .form-check-normal & {
      border-radius: $form-check-input-border-radius;
    }

    .form-check-small & {
      border-radius: $form-check-input-border-radius-sm;
    }
  }

  &[type='radio'] {
    border-radius: 50%;
  }

  // Unchecked states.
  background-color: $form-check-input-bg;
  border: $form-check-input-border;

  .error & {
    border-color: $form-check-border-color-error;
  }

  &:hover {
    border-color: $form-check-border-color-hover;
  }

  &:active {
    border-color: $form-check-border-color-active;
  }

  &:focus-visible {
    border-color: $form-check-border-color-focus;
    border-width: 3px;
  }

  &[disabled],
  &:disabled {
    background-color: $form-check-input-bg-disabled;
    border-color: transparent;
    cursor: not-allowed;

    ~ .form-check-label {
      cursor: not-allowed;
      color: $form-check-label-color-disabled;
    }
  }

  // Checked states.
  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: transparent;

    &[type='checkbox'] {
      background-image: shared.escape-svg($form-check-input-checked-bg-image);

      &:hover {
        background-color: $form-check-input-checked-bg-color-hover;
      }

      &:active {
        background-color: $form-check-input-checked-bg-color-active;
      }

      &:focus-visible {
        border-color: $form-check-input-checked-bg-color-active;
      }

      &:disabled,
      &[disabled] {
        background-color: $form-check-input-bg-disabled;
        background-image: shared.escape-svg($form-check-input-checked-bg-image-disabled);
      }
    }

    &[type='radio'] {
      background-image: shared.escape-svg($form-check-radio-checked-bg-image);
      background-color: $form-check-input-bg;
      border-color: $form-check-input-border-color;

      &:hover {
        border-color: $form-check-border-color-hover;
      }

      &:active {
        border-color: $form-check-border-color-active;
      }

      &:focus-visible {
        border-color: $form-check-input-checked-bg-color-active;
      }

      &:disabled,
      &[disabled] {
        border-color: transparent;
        background-color: $form-check-input-bg-disabled;
        background-image: shared.escape-svg($form-check-radio-checked-bg-image-disabled);
      }
    }
  }

  // Indeterminate states.
  /* stylelint-disable-next-line no-descending-specificity */
  &[type='checkbox']:indeterminate {
    background-color: $form-check-input-checked-bg-color;
    border-color: transparent;
    background-image: shared.escape-svg($form-check-input-indeterminate-bg-image);

    &:hover {
      background-color: $form-check-input-checked-bg-color-hover;
    }

    &:active {
      background-color: $form-check-input-checked-bg-color-active;
    }

    &:focus-visible {
      border-color: $form-check-input-checked-bg-color-active;
    }

    &:disabled,
    &[disabled] {
      background-color: $form-check-input-bg-disabled;
      background-image: shared.escape-svg($form-check-input-indeterminate-bg-image-disabled);
    }
  }
}

// Switch

// Sizes
$form-switch-border-size: 2px;
$form-switch-width: 40px;
$form-switch-height: 24px;
$form-switch-width-sm: 32px;
$form-switch-height-sm: 16px;
$form-switch-padding-start: $form-switch-width + 8px;
$form-switch-padding-start-sm: $form-switch-width-sm + 8px;
$form-switch-knob-size: $form-switch-height - 2 * $form-switch-border-size;
$form-switch-knob-size-sm: $form-switch-height-sm - 2 * $form-switch-border-size;

// Background
$form-switch-bg-color: shared.color('primary300');
$form-switch-checked-bg-color: shared.color('primary500');
$form-switch-checked-bg-color-hover: shared.color('primary600');
$form-switch-checked-bg-color-active: shared.color('primary700');
$form-switch-checked-bg-color-focus: $form-switch-checked-bg-color;

// Knob
$form-switch-color: shared.color('bg100');
$form-switch-color-hover: shared.color('neutral100');
$form-switch-color-active: shared.color('primary400');
$form-switch-color-focus: $form-switch-color;
$form-switch-color-disabled: shared.color('text200');

// Border
$form-switch-border-color-focus: shared.color('primary700');
$form-switch-border-color-disabled: shared.color('text300');

.form-switch {
  position: relative;
  display: flex;
  flex-direction: column;

  &.form-check-normal {
    padding-left: $form-switch-padding-start;

    .form-check-label {
      line-height: $form-switch-height;
    }
  }

  &.form-check-small {
    padding-left: $form-switch-padding-start-sm;

    .form-check-label {
      line-height: $form-switch-height-sm;
    }
  }

  .form-check-input {
    pointer-events: none;
    opacity: 0;
    position: absolute;
  }
}

.form-switch-button {
  border: 2px solid transparent;
  background-color: $form-switch-bg-color;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  print-color-adjust: exact;

  .form-check-normal & {
    width: $form-switch-width;
    height: $form-switch-height;
    border-radius: $form-switch-width;
  }

  .form-check-small & {
    width: $form-switch-width-sm;
    height: $form-switch-height-sm;
    border-radius: $form-switch-width-sm;
  }

  .form-check-input:focus-visible ~ .form-check-label & {
    border-color: $form-switch-border-color-focus;
  }

  .form-check-input:disabled ~ .form-check-label & {
    border-color: $form-switch-border-color-disabled;
    border-width: 1px;
    background-color: shared.color('bg100');
    cursor: not-allowed;
  }

  // Checked
  .form-check-input:checked ~ .form-check-label & {
    background-color: $form-switch-checked-bg-color;
  }

  .form-check-input:checked:hover ~ .form-check-label & {
    background-color: $form-switch-checked-bg-color-hover;
  }

  .form-check-input:checked:active ~ .form-check-label & {
    background-color: $form-switch-checked-bg-color-active;
  }

  .form-check-input:checked:disabled ~ .form-check-label & {
    background-color: shared.color('bg100');
  }
}

.form-switch-knob {
  background-color: $form-switch-color;
  transition: transform 0.2s;

  .form-check-normal & {
    width: $form-switch-knob-size;
    height: $form-switch-knob-size;
    border-radius: $form-switch-knob-size;
  }

  .form-check-small & {
    width: $form-switch-knob-size-sm;
    height: $form-switch-knob-size-sm;
    border-radius: $form-switch-knob-size-sm;
  }

  .form-check-input:hover ~ .form-check-label & {
    background-color: $form-switch-color-hover;
  }

  .form-check-input:active ~ .form-check-label & {
    background-color: $form-switch-color-active;
  }

  .form-check-input:focus-visible ~ .form-check-label & {
    background-color: $form-switch-color-focus;
  }

  .form-check-input:disabled ~ .form-check-label & {
    background-color: $form-switch-color-disabled;
    margin: 1px;
  }

  // Checked
  .form-check-input:checked ~ .form-check-label & {
    transform: translateX($form-switch-width - $form-switch-knob-size - $form-switch-border-size * 2);
  }

  .form-check-input:checked:hover ~ .form-check-label & {
    background-color: shared.color('bg100');
  }

  .form-check-input:checked:active ~ .form-check-label & {
    background-color: shared.color('bg100');
  }

  .form-check-input:checked:disabled ~ .form-check-label & {
    background-color: $form-switch-color-disabled;
  }
}
